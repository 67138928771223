<script>
import Layout from "../layouts/main.vue";
import PageHeader from "../../components/page-header.vue";
import PassSearchVue from "../../components/rail/PassSearch.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "",
  components: {
    Layout,
    PageHeader,
    PassSearchVue
  },
  data() {
    return {
      title: "Rail Passes",
      items: [
        {
          // #TODO
          text: "Rail",
          to: { name: "rail-home" }
        },
        {
          text: "Pass Results",
          active: true
        }
      ],
      isCllapseBtnHidden: false,
      isExpandBtnHidden: true,
      isOverLayShow: false,
      filteredPasses: []
    };
  },
  created() {
    let railPassResult = JSON.parse(sessionStorage.getItem("railPassResults"));
    this.filteredPasses = Object.assign([], railPassResult);
  },
  computed: {
    ...mapGetters("rail", ["railPassResults"])
  },
  watch: {
    railPassResults() {
      //重新搜索成功，清空原来的数据
      this.resetDefaul();

      this.isOverLayShow = false;
      this.filteredPasses = Object.assign([], this.railPassResults);
    }
  },
  methods: {
    ...mapActions("rail", ["selectPass"]),
    nextStep: function(selectedPass) {
      this.selectPass({
        selectedPass: selectedPass
      });
      this.$router.push({
        name: "passOptions"
      });
    },
    togglBtn() {
      this.isExpandBtnHidden = !this.isExpandBtnHidden;
      this.isCllapseBtnHidden = !this.isCllapseBtnHidden;
    },
    reSearch() {
      this.isOverLayShow = true;
    },
    resetDefaul() {
      this.filteredPasses = [];
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <div class="d-flex justify-content-between border-bottom">
        <div>
          <span class="font-size-16">Modify your search</span>
        </div>
        <div>
          <i
            v-b-toggle.pass-search-collapse-inner
            class="mdi mdi-arrow-up-drop-circle-outline font-size-20"
            v-show="isCllapseBtnHidden"
            @click="togglBtn"
          ></i>
          <i
            v-b-toggle.pass-search-collapse-inner
            class="mdi mdi-arrow-down-drop-circle-outline font-size-20"
            v-show="isExpandBtnHidden"
            @click="togglBtn"
          ></i>
        </div>
      </div>
      <b-collapse id="pass-search-collapse-inner" class="mt-2">
        <PassSearchVue
          :is-in-pass-list-page="true"
          @reSearch="reSearch"
        ></PassSearchVue>
      </b-collapse>
    </b-card>
    <b-overlay :show="isOverLayShow" rounded="sm">
      <b-card
        v-for="(item, index) in filteredPasses"
        :key="`pass-option-${index}`"
        class="mb-2"
      >
        <b-row>
          <b-col cols="10">
            <!-- <b-card
                img-src="https://placekitten.com/200/100" 
                img-alt="Card image" 
                img-left
                class=" mb-2"
                no-body
                > -->
            <b-card class=" mb-2" no-body>
              <b-card-body>
                <h5 class="font-size-14">{{ item.label }}</h5>
                <ul class="list-unstyled">
                  <li>
                    {{
                      item.passType == "CONSECUTIVE"
                        ? "Continuous Travel"
                        : "Flexible Travel"
                    }}
                  </li>
                  <li>
                    Choose to travel{{
                      item.passType == "CONSECUTIVE"
                        ? " continuously on either "
                        : " flexibly between "
                    }}
                    <span
                      v-for="(validity, idx) in item.validityOptions"
                      :key="idx"
                      >{{ validity }},&nbsp;</span
                    >
                  </li>
                  <li v-if="item.passType == 'FLEXIBLE'">
                    Travel days can be anytime within {{ item.within }} of the
                    first date of travel, as written on your pass.
                  </li>
                  <li v-html="item.includes"></li>
                </ul>
              </b-card-body>
            </b-card>
          </b-col>

          <b-col>
            <p>
              <small>From</small>&nbsp; &nbsp;
              <span class="text-danger"
                ><b
                  >{{ item.sellingCurrencyCode }}&nbsp;&nbsp;
                  {{ item.sellingBestPrice }}</b
                >
              </span>
            </p>
            <b-button size="sm" variant="success" @click="nextStep(item)"
              >Select</b-button
            >
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </Layout>
</template>
